exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-projects-ai-cocktails-index-mdx": () => import("./../../../src/pages/projects/ai-cocktails/index.mdx" /* webpackChunkName: "component---src-pages-projects-ai-cocktails-index-mdx" */),
  "component---src-pages-projects-ai-cupcakes-index-mdx": () => import("./../../../src/pages/projects/ai-cupcakes/index.mdx" /* webpackChunkName: "component---src-pages-projects-ai-cupcakes-index-mdx" */),
  "component---src-pages-projects-ai-gin-index-mdx": () => import("./../../../src/pages/projects/ai-gin/index.mdx" /* webpackChunkName: "component---src-pages-projects-ai-gin-index-mdx" */),
  "component---src-pages-projects-ai-ice-cream-index-mdx": () => import("./../../../src/pages/projects/ai-ice-cream/index.mdx" /* webpackChunkName: "component---src-pages-projects-ai-ice-cream-index-mdx" */),
  "component---src-pages-projects-ai-poetry-index-mdx": () => import("./../../../src/pages/projects/ai-poetry/index.mdx" /* webpackChunkName: "component---src-pages-projects-ai-poetry-index-mdx" */),
  "component---src-pages-projects-bbc-big-night-in-index-mdx": () => import("./../../../src/pages/projects/bbc-big-night-in/index.mdx" /* webpackChunkName: "component---src-pages-projects-bbc-big-night-in-index-mdx" */),
  "component---src-pages-projects-bbc-save-our-summer-index-mdx": () => import("./../../../src/pages/projects/bbc-save-our-summer/index.mdx" /* webpackChunkName: "component---src-pages-projects-bbc-save-our-summer-index-mdx" */),
  "component---src-pages-projects-cheltenham-science-festival-aida-index-mdx": () => import("./../../../src/pages/projects/cheltenham-science-festival-aida/index.mdx" /* webpackChunkName: "component---src-pages-projects-cheltenham-science-festival-aida-index-mdx" */),
  "component---src-pages-projects-discover-ai-index-mdx": () => import("./../../../src/pages/projects/discover-ai/index.mdx" /* webpackChunkName: "component---src-pages-projects-discover-ai-index-mdx" */),
  "component---src-pages-projects-ganta-claus-index-mdx": () => import("./../../../src/pages/projects/ganta-claus/index.mdx" /* webpackChunkName: "component---src-pages-projects-ganta-claus-index-mdx" */),
  "component---src-pages-projects-solution-ip-index-mdx": () => import("./../../../src/pages/projects/solution-ip/index.mdx" /* webpackChunkName: "component---src-pages-projects-solution-ip-index-mdx" */),
  "component---src-pages-test-mdx": () => import("./../../../src/pages/test.mdx" /* webpackChunkName: "component---src-pages-test-mdx" */)
}

